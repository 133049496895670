import React from 'react';

import imgCaiti326Src from '@headway/shared/assets/img/testimonials/caiti326.jpg';
import imgNinarstepanovSrc from '@headway/shared/assets/img/testimonials/ninarstepanov.jpg';
import imgRmboutonSrc from '@headway/shared/assets/img/testimonials/rmbouton.jpg';
import imgSamimainSrc from '@headway/shared/assets/img/testimonials/samimain.jpg';
import imgTikhbanaSrc from '@headway/shared/assets/img/testimonials/tikhbana.jpg';
import {
  CopyTitle,
  Section,
  SectionContainer,
  Slider,
} from '@headway/ui/landing';
import { theme } from '@headway/ui/theme';

import { Testimonial, TestimonialMention } from './Testimonial';

export const TwitterTestimonialCarousel = () => {
  return (
    <SectionContainer>
      <Section centered={true}>
        <CopyTitle>What people are saying</CopyTitle>
        <Slider
          css={{ marginTop: theme.space.xl2 }}
          aria-label="testimonial carousel"
        >
          <Testimonial
            photoSrc={imgRmboutonSrc}
            name="Rachel Bouton"
            title="@RMBouton"
            href="https://twitter.com/RMBouton/status/1308878318125670400"
            quote={
              <React.Fragment>
                If you're looking for a good therapist,{' '}
                <TestimonialMention>Headway</TestimonialMention> is awesome. You
                plug in your insurance info and it gives you a list of
                therapists who take your insurance. They also process all the
                payment and billing without you or your provider having to worry
                about it.
              </React.Fragment>
            }
          />
          <Testimonial
            photoSrc={imgCaiti326Src}
            name="Caiti Donovan"
            title="@Caiti326"
            href="https://twitter.com/Caiti326/status/1308439168599588865"
            quote={
              <React.Fragment>
                I found my therapist through{' '}
                <TestimonialMention>Headway</TestimonialMention> (
                <TestimonialMention>https://headway.co</TestimonialMention>) and
                love her! I found trying to search via my insurance’s website to
                be v clunky and overall not great. Headway accepts insurance but
                they take out the hassle of search & payment so you focus on
                finding a good fit!
              </React.Fragment>
            }
          />
          <Testimonial
            photoSrc={imgTikhbanaSrc}
            name="tikh"
            title="@tikhbana"
            href="https://twitter.com/tikhbana/status/1276922540485750785"
            quote={
              <React.Fragment>
                Woah, def recommend this for folks looking for therapists{' '}
                <TestimonialMention>https://headway.co</TestimonialMention>
              </React.Fragment>
            }
          />
          <Testimonial
            photoSrc={imgSamimainSrc}
            name="Sami Main, CLC"
            title="@samimain"
            href="https://twitter.com/samimain/status/1161263874768850944"
            quote={
              <React.Fragment>
                I used{' '}
                <TestimonialMention>https://headway.co</TestimonialMention> to
                find mine and it was much much easier than I anticipated!
              </React.Fragment>
            }
          />
          <Testimonial
            photoSrc={imgNinarstepanovSrc}
            name="Nina Stepanov"
            title="@ninarstepanov"
            href="https://twitter.com/ninarstepanov/status/1245036017536716800"
            quote={
              <React.Fragment>
                If you're in NYC, check out{' '}
                <TestimonialMention>https://headway.co</TestimonialMention>
                <br />
                <br />
                quick, easy and friendly staff
              </React.Fragment>
            }
          />
        </Slider>
      </Section>
    </SectionContainer>
  );
};
