import React from 'react';

import { Logo, Logos } from '@headway/ui/landing';
import { theme } from '@headway/ui/theme';

export const PayerPartners: React.FC = () => {
  // TODO: Add back payer logos when approved
  // TODO: Add BCBS once we resolve how to include specific Blues
  return (
    <React.Fragment>
      <Logos>
        <Logo css={{ color: theme.color.aetna }}>
          Aetna
          {/*<Aetna height={50} width={150} />*/}
        </Logo>
        <Logo css={{ color: theme.color.anthem }}>
          Anthem
          {/*<Anthem height={50} width={150} />*/}
        </Logo>
        <Logo css={{ color: theme.color.cigna }}>
          Cigna
          {/*<Cigna height={50} width={150} />*/}
        </Logo>
      </Logos>
      <Logos>
        <Logo css={{ color: theme.color.uhc }}>
          Oxford
          {/*<UnitedOxford height={50} width={150} />*/}
        </Logo>
        <Logo css={{ color: theme.color.uhc }}>
          UnitedHealthcare
          {/*<United height={50} width={150} />*/}
        </Logo>
        <Logo css={{ color: theme.color.oscar }}>
          Oscar
          {/*<Oscar height={50} width={150} />*/}
        </Logo>
      </Logos>
    </React.Fragment>
  );
};
