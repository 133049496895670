import React, { useState } from 'react';

import {
  ProviderSearchExperimentCareNavigatorModalContinueButtonClickedEvent,
  ProviderSearchExperimentCareNavigatorModalSkipCloseButtonClickedEvent,
} from '@headway/avo';
import { BodyText } from '@headway/helix/BodyText';
import { Button } from '@headway/helix/Button';
import { GuidanceCard } from '@headway/helix/GuidanceCard';
import { LinkButton } from '@headway/helix/LinkButton';
import { Modal, ModalContent, ModalFooter } from '@headway/helix/Modal';
import { Radio } from '@headway/helix/Radio';
import { RadioGroup } from '@headway/helix/RadioGroup';
import { HandHeart } from '@headway/icons';
import { trackEvent } from '@headway/shared/utils/analytics';

const modalContentByVariant = {
  stepDown: {
    title: 'Curious about what type of care might be right for you?',
    body: "Connect with a care navigator today. In a brief meeting, we'll ask you a few questions about your needs, then provide personalized recommendations and craft an individualized care plan just for you.",
    cancelText: 'Skip',
    calendlyLink:
      'https://calendly.com/d/ckt9-fm5-4w8/meeting-with-headway-care-navigation-team',
  },
  stepUp: {
    title: 'Need additional support in choosing the right provider?',
    body: "Connect with a care navigator today over a free, 15 minute intro call. During this call, we'll learn more about what you're looking for, and connect you with a great in-network provider that meets your needs and preferences.",
    cancelText: 'No thanks',
    calendlyLink:
      'https://calendly.com/d/cp8q-2fw-5yq/headway-care-navigator-call?utm_source=modal',
  },
};

interface CareNavigatorModalProps {
  isOpen: boolean;
  variant: 'stepUp' | 'stepDown';
  onClose: (shouldRedirectToSearchResults: boolean) => void;
}

export const CareNavigatorModal: React.FC<CareNavigatorModalProps> = ({
  isOpen,
  variant,
  onClose,
}) => {
  const [showingSmsInstructions, setShowingSmsInstructions] =
    useState<boolean>(false);
  const [stepDownCommOption, setStepDownCommOption] =
    useState<string>('calendly');
  return (
    <Modal
      title={modalContentByVariant[variant].title}
      isOpen={isOpen}
      onDismiss={() => {
        trackEvent({
          name: 'Provider Search Experiment Care Navigator Modal Skip/Close Button Clicked',
          properties: {
            variantName: variant,
          },
        } as ProviderSearchExperimentCareNavigatorModalSkipCloseButtonClickedEvent);
        onClose(true);
      }}
    >
      {showingSmsInstructions ? (
        <>
          <ModalContent>
            <BodyText>
              To help us assess your needs, please text us at{' '}
              <strong>347-218-3522</strong> with the following details. We will
              respond within 24 hours.
            </BodyText>
            <BodyText>
              <ul>
                <li>Patient first name</li>
                <li>Patient last name</li>
                <li>Patient email</li>
                <li>Brief description of therapeutic needs</li>
              </ul>
            </BodyText>
          </ModalContent>
          <ModalFooter>
            <Button
              variant="secondary"
              onPress={() => {
                setShowingSmsInstructions(false);
              }}
            >
              Back
            </Button>
            <Button
              variant="secondary"
              onPress={() => {
                onClose(true);
              }}
            >
              Done
            </Button>
          </ModalFooter>
        </>
      ) : (
        <>
          <ModalContent>
            <GuidanceCard variant="positive" icon={<HandHeart />}>
              <BodyText>
                <strong>Care navigators are here to help</strong>
              </BodyText>
            </GuidanceCard>
            <p>
              <BodyText>{modalContentByVariant[variant].body}</BodyText>
            </p>
            <p>
              <RadioGroup
                name="stepDownCommOption"
                label={''}
                value={stepDownCommOption}
                onChange={(value) => setStepDownCommOption(value)}
              >
                <Radio value={'calendly'}>Schedule a call with us</Radio>
                <Radio value={'sms'}>Send us an SMS</Radio>
              </RadioGroup>
            </p>
          </ModalContent>
          <ModalFooter>
            <Button
              variant="secondary"
              onPress={() => {
                onClose(true);
              }}
            >
              {modalContentByVariant[variant].cancelText}
            </Button>
            {stepDownCommOption === 'calendly' ? (
              <LinkButton
                variant="primary"
                href={modalContentByVariant[variant].calendlyLink}
                onPress={() => {
                  trackEvent({
                    name: 'Provider Search Experiment Care Navigator Modal Continue Button Clicked',
                    properties: {
                      contactType: 'call',
                      variantName: variant,
                    },
                  } as ProviderSearchExperimentCareNavigatorModalContinueButtonClickedEvent);
                }}
              >
                Continue
              </LinkButton>
            ) : (
              <Button
                variant="primary"
                onPress={() => {
                  trackEvent({
                    name: 'Provider Search Experiment Care Navigator Modal Continue Button Clicked',
                    properties: {
                      contactType: 'sms',
                      variantName: variant,
                    },
                  } as ProviderSearchExperimentCareNavigatorModalContinueButtonClickedEvent);
                  setShowingSmsInstructions(true);
                }}
              >
                Continue
              </Button>
            )}
          </ModalFooter>
        </>
      )}
    </Modal>
  );
};
