import { css } from '@emotion/react';
import { Divider } from '@mui/material';
import { useState } from 'react';

import { Button } from '@headway/helix/Button';
import { Modal } from '@headway/ui/Modal';
import { theme } from '@headway/ui/theme';

const buttonContainerStyle = css`
  bottom: 0px;
  width: 100%;
  ${theme.media.smallDown} {
    position: fixed;
    padding-bottom: ${theme.space.base};
    hr {
      margin: 0px;
    }
  }
`;

const buttonCssStyle = css`
  display: flex;
  justify-content: flex-end;
  padding-top: ${theme.space.base};
  padding-right: ${theme.space.xl};

  ${theme.media.smallDown} {
    width: 100%;
  }
`;

type ConfirmationModalProps = {
  isOpen: boolean;
  title: string;
  description: string;
  buttonText: string;
  handleOnClick: () => void;
  onClose: () => void;
};

export const NavigationConfirmationModal: React.FC<ConfirmationModalProps> = ({
  isOpen,
  onClose,
  title,
  description,
  handleOnClick,
  buttonText,
}) => {
  const [isSubmitting, setIsSubmitting] = useState(false);

  return (
    <Modal
      open={isOpen}
      keepMounted={false}
      onClose={onClose}
      title={title}
      sx={{
        '.MuiDialogContent-root': {
          paddingLeft: '0px',
          paddingRight: '0px',
        },
      }}
    >
      <p
        css={{
          padding: `${theme.space.xs2} ${theme.space.xl}`,
        }}
      >
        {description}
      </p>
      <div css={buttonContainerStyle}>
        <Divider
          variant="fullWidth"
          css={{
            paddingTop: theme.space.xs,
            margin: `0px ${theme.space.xs}`,
          }}
        />
        <div css={buttonCssStyle}>
          <Button
            type="submit"
            variant="primary"
            size="large"
            disabled={isSubmitting}
            onPress={() => {
              setIsSubmitting(true);
              handleOnClick();
            }}
          >
            {buttonText}
          </Button>
        </div>
      </div>
    </Modal>
  );
};
