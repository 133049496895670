import { css } from '@emotion/react';
import { Formik } from 'formik';
import * as Yup from 'yup';

import { UserApi } from '@headway/api/resources/UserApi';
import { Button } from '@headway/helix/Button';
import { Form, FormStickyFooter } from '@headway/helix/Form';
import { FormControl } from '@headway/helix/FormControl';
import { TextField } from '@headway/helix/TextField';
import { logException } from '@headway/shared/utils/sentry';
import { Modal } from '@headway/ui/Modal';
import { theme } from '@headway/ui/theme';
import { notifyError, notifySuccess } from '@headway/ui/utils/notify';

import { withStores, WithStoresChildProps } from '../../stores/withStores';

const buttonContainerStyle = css`
  ${theme.media.smallDown} {
    bottom: 0px;
    padding: 0 ${theme.space.base};
    position: fixed;
    width: 100%;
    left: 0;

    button {
      font-size: ${theme.fontSize.sm};
      padding-left: ${theme.space.sm};
      padding-right: ${theme.space.sm};
    }
  }
`;

type Props = {
  isOpen: boolean;
  close: () => void;
  onSuccessfulSubmit: (email: string) => void;
  buttonText?: string;
  description: string;
  title: string;
} & WithStoresChildProps;

const formSchema = Yup.object().shape({
  email: Yup.string()
    .email('Please provide a valid email')
    .required('Please provide a valid email'),
});

const UnavailableFieldMailingListModal: React.FC<Props> = ({
  AuthStore,
  isOpen,
  close,
  description,
  title,
  buttonText = 'Let me know',
  onSuccessfulSubmit,
}) => {
  return (
    <Modal
      title={title}
      open={isOpen}
      keepMounted={false}
      onClose={close}
      /* while using the old modal with Helix, we have to remove unnecessary padding */
      dialogContentStyle={{ paddingBottom: 0 }}
    >
      <p>{description}</p>
      <Formik
        initialValues={{
          email:
            AuthStore.user.email || AuthStore.user.questionnaireEmail || '',
        }}
        validationSchema={formSchema}
        onSubmit={async ({ email }) => {
          try {
            const user = await UserApi.updateUser(AuthStore.user.id, {
              questionnaireEmail: email,
            });
            AuthStore.setUser(user);
            onSuccessfulSubmit(email);
            notifySuccess("We'll be in touch!");
            close();
          } catch (e: AnyTS4TryCatchUnknownError) {
            notifyError('There was a problem recording your email.');
            logException(e);
          }
        }}
        render={(props) => (
          <Form {...props}>
            <FormControl
              name="email"
              component={TextField}
              label="Email"
              type="email"
            />
            <div css={buttonContainerStyle}>
              <FormStickyFooter>
                <Button type="submit" disabled={props.isSubmitting}>
                  {buttonText}
                </Button>
              </FormStickyFooter>
            </div>
          </Form>
        )}
      />
    </Modal>
  );
};

export default withStores(UnavailableFieldMailingListModal);
