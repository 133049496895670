import { css } from '@emotion/react';
import { CloseTwoTone } from '@mui/icons-material';
import {
  ButtonBase,
  Checkbox,
  Dialog,
  Divider,
  IconButton,
  ListItemIcon,
  ListItemText,
  ListSubheader,
  MenuItem,
  Slide,
  SlideProps,
  useMediaQuery,
} from '@mui/material';
import React from 'react';

import { Button } from '@headway/ui/Button';
import { FieldControl } from '@headway/ui/form/FieldControl';
import { FieldSelect } from '@headway/ui/form/FieldSelect';
import { theme } from '@headway/ui/theme';
import { VisuallyHidden } from '@headway/ui/VisuallyHidden';

const CONCERNS_FIELD_NAME = 'concerns';

const selectStyle = css`
  .MuiOutlinedInput-notchedOutline {
    border: none;
  }

  .MuiOutlinedInput-notchedOutline legend {
    overflow: visible;
    visibility: visible;
    height: auto;
    padding-top: ${theme.space.lg};
    font-size: ${theme.fontSize.base};
    font-family: ${theme.fontFamily.postGrotesk};
    margin-left: 0.688rem;
    @media (max-width: 768px) {
      margin-left: 0;
    }

    span {
      opacity: 1 !important;
    }
  }

  .MuiSelect-select {
    font-family: ${theme.fontFamily.postGrotesk};
    padding-left: 16px;
    padding-bottom: 4px;
    padding-right: 0;
    padding-top: 5px;
    border-radius: 20px !important;
    border: 1px solid ${theme.color.lightGray};
    color: ${theme.color.textGray};
    font-size: ${theme.fontSize.xs};

    &:hover {
      background-color: rgba(0, 0, 0, 0.04);
    }
  }
`;
const shareLaterStyle = css`
  font-family: ${theme.fontFamily.postGrotesk};
  font-weight: 400;
  font-size: 1rem;
  line-height: 1.5;
  letter-spacing: 0.00938em;
  width: 100%;
  justify-content: left;
  padding-left: ${theme.space.lg};
  padding-right: ${theme.space.lg};
  height: ${theme.space.xl3};
  margin-bottom: ${theme.space.xs};

  &:hover {
    -webkit-text-decoration: none;
    text-decoration: none;
    background-color: rgba(0, 0, 0, 0.04);
  }
`;
const fieldControlStyle = css`
  margin-bottom: 0;
  flex-grow: 0;
`;
const subtitlePlaceholderStyle = css`
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  color: ${theme.color.textGray};
  line-height: 0.875rem;
`;
const labelStyle = css`
  font-size: ${theme.fontSize.base};
  font-family: ${theme.fontFamily.postGrotesk};
  color: ${theme.color.black};
  line-height: 0.875rem;
  margin-bottom: 0.375rem;
`;
const contentWithLabelStyle = css`
  padding-top: ${theme.space.xs};
  padding-bottom: ${theme.space.xs};
`;

interface ContentWithLabelProps {
  label: string;
  value: string;
}

const ContentWithLabel = ({
  label,
  value,
}: ContentWithLabelProps): JSX.Element => {
  return (
    <div css={contentWithLabelStyle}>
      <div css={labelStyle} className={'label'}>
        {label}
      </div>
      <div css={subtitlePlaceholderStyle}>{value}</div>
    </div>
  );
};

const SlideTransition = React.forwardRef(function Transition(
  props: SlideProps & { children?: React.ReactElement<any, any> },
  ref: React.Ref<unknown>
) {
  return <Slide direction="up" timeout={150} ref={ref} {...props} />;
});

interface CustomOptionProps {
  key: any;
  disabled?: boolean;
  value: any;
  text: string | undefined;
  dataTestId?: string;
}

interface CustomFieldSelectProps {
  name: string;
  label: string;
  renderValue: (selected: any) => any;
  onSelect?: (item?: any) => void;
  onClose?: () => void;
  onOpen?: () => void;
  open?: boolean;
  options: CustomOptionProps[];
  setFieldValue: (name: string, value: any) => void;
  initialValue: any;
  multiple?: boolean;
  values?: any;
  dirty?: boolean;
  shareLater?: boolean;
  shareLaterText?: string;
  containerStyle?: any;
  disabled?: boolean;
  onClick?: (ev: any) => void;
  value?: any;
  inputStyle?: any;
  withPlaceholder?: boolean;
  placeholder?: string;
  className?: string;
  testId?: string;
}

const CustomFieldSelectWithLabel = ({
  name,
  label,
  renderValue,
  onClose,
  onOpen,
  onSelect,
  open,
  options,
  setFieldValue,
  initialValue,
  multiple,
  values,
  dirty,
  shareLater,
  shareLaterText = 'I’ll share later',
  containerStyle,
  disabled,
  onClick,
  value,
  inputStyle,
  placeholder = 'Placeholder',
  className,
  testId,
}: CustomFieldSelectProps): JSX.Element => {
  const isMobileView = useMediaQuery(theme.media.smallDown);
  const [isDialogOpened, setIsDialogOpened] = React.useState<boolean>(false);
  const [isSelectOpened, setIsSelectOpened] = React.useState<
    boolean | undefined
  >(false);
  const [selectedValue, setSelectedValue] = React.useState(value);
  const [shareLaterIsSelected, setShareLaterIsSelected] = React.useState(false);
  const [saveHasBeenClicked, setSaveHasBeenClicked] = React.useState(false);

  const handleOnOpen = () => {
    if (onOpen) onOpen();
    if (isMobileView) {
      setIsDialogOpened(true);
    } else {
      setIsSelectOpened(true);
    }
    setSaveHasBeenClicked(false);
  };

  const handleOnClose = () => {
    if (onClose) onClose();
    if (isMobileView) {
      setIsDialogOpened(false);
    } else {
      setIsSelectOpened(false);
    }
  };

  React.useEffect(() => {
    setSelectedValue(value);
  }, [value]);

  React.useEffect(() => {
    if (isMobileView) setIsDialogOpened(open ? open : false);
    else setIsSelectOpened(open);
  }, [open]);

  const handleOnItemClick = (value: any) => {
    if (onSelect) onSelect(value);
    setSelectedValue(value);
  };

  return (
    <div className={className || undefined} css={{ alignSelf: 'center' }}>
      <FieldControl
        name={name}
        css={[fieldControlStyle, containerStyle ? containerStyle : null]}
      >
        <FieldSelect
          testId={testId}
          hasInputLabel={false}
          disabled={disabled}
          variant="outlined"
          css={[selectStyle, inputStyle ? inputStyle : null]}
          onClose={() => {
            if (multiple) setFieldValue(name, value);
            handleOnClose();
          }}
          onOpen={handleOnOpen}
          open={isSelectOpened}
          multiple={multiple}
          onClick={(ev: any) => {
            if (onClick) onClick(ev);
          }}
          onChange={(event) => {
            if (name === CONCERNS_FIELD_NAME)
              handleOnItemClick(event.target.value);
          }}
          renderValue={(selected: any) => {
            if (shareLater && shareLaterIsSelected)
              return <ContentWithLabel label={label} value={shareLaterText} />;
            if (multiple) {
              if (saveHasBeenClicked) {
                if (selected.length === 0 || values[name] === initialValue) {
                  return <ContentWithLabel label={label} value={placeholder} />;
                } else if (selected.length === 1) {
                  return (
                    <ContentWithLabel
                      label={label}
                      value={renderValue(values[name][0])}
                    />
                  );
                }
                return (
                  <ContentWithLabel
                    label={label}
                    value={values[name]
                      .map((item: any) => renderValue(item))
                      .join(', ')}
                  />
                );
              }
              if (
                selectedValue.length === 0 ||
                selectedValue === initialValue
              ) {
                return <ContentWithLabel label={label} value={placeholder} />;
              } else if (selectedValue.length === 1) {
                return (
                  <ContentWithLabel
                    label={label}
                    value={renderValue(selectedValue[0])}
                  />
                );
              }
              return (
                <ContentWithLabel
                  label={label}
                  value={selectedValue
                    .map((item: any) => renderValue(item))
                    .join(', ')}
                />
              );
            } else {
              if (selectedValue === initialValue) {
                return <ContentWithLabel label={label} value={placeholder} />;
              }
              return (
                <ContentWithLabel
                  label={label}
                  value={renderValue(selectedValue)}
                />
              );
            }
          }}
          value={selectedValue}
        >
          {shareLater && [
            multiple ? (
              <div key="later_cta">
                <ButtonBase
                  css={shareLaterStyle}
                  onClick={() => {
                    if (onSelect) onSelect([]);
                    if (onClose) onClose();
                    setFieldValue(name, []);
                    setShareLaterIsSelected(true);
                    setIsSelectOpened(false);
                  }}
                >
                  {shareLaterText}
                </ButtonBase>
              </div>
            ) : (
              <MenuItem
                key={'share_later'}
                value={'share_later'}
                onClick={() => {
                  if (onSelect) onSelect(initialValue);
                  if (onClose) onClose();
                  setFieldValue(name, initialValue);
                  setShareLaterIsSelected(true);
                  setIsSelectOpened(false);
                }}
              >
                {shareLaterText}
              </MenuItem>
            ),
            <Divider
              key="divider"
              css={{
                marginLeft: theme.space.lg,
                marginRight: theme.space.lg,
              }}
            />,
            <div key="spacer" css={{ height: theme.space.xs }} />,
          ]}
          {multiple
            ? options.map((option: CustomOptionProps, index) => {
                return option.disabled ? (
                  <ListSubheader
                    disableSticky
                    css={{
                      fontFamily: theme.fontFamily.postGrotesk,
                      pointerEvents: 'none',
                    }}
                    key={option.key}
                  >
                    {option.text}
                  </ListSubheader>
                ) : (
                  <MenuItem
                    key={option.key}
                    value={option.value}
                    css={{
                      padding: `0 ${theme.space.xs} 0 ${theme.space.xs}`,
                      fontFamily: theme.fontFamily.postGrotesk,
                      marginBottom:
                        index === options.length - 1 ? theme.space.xs : 0,
                    }}
                    onClick={() => {
                      if (shareLater) setShareLaterIsSelected(false);
                    }}
                  >
                    <ListItemIcon>
                      <Checkbox
                        checked={
                          values[name].filter(
                            (val: any) => val === option.value
                          ).length > 0
                        }
                      />
                    </ListItemIcon>
                    <ListItemText
                      css={{ paddingRight: theme.space.sm }}
                      primaryTypographyProps={{
                        style: {
                          fontFamily: theme.fontFamily.postGrotesk,
                        },
                      }}
                      primary={option.text}
                    />
                  </MenuItem>
                );
              })
            : options.map((option: CustomOptionProps, index) =>
                option.disabled ? (
                  <ListSubheader
                    disableSticky
                    css={{ fontFamily: theme.fontFamily.postGrotesk }}
                    key={option.key}
                  >
                    {option.text}
                  </ListSubheader>
                ) : (
                  <MenuItem
                    css={{
                      fontFamily: theme.fontFamily.postGrotesk,
                      marginBottom:
                        index === options.length - 1 ? theme.space.xs : 0,
                    }}
                    key={option.key}
                    id={option.key}
                    value={option.value}
                    onClick={(ev) => {
                      if (shareLater) setShareLaterIsSelected(false);
                      handleOnItemClick(option.value);
                    }}
                    data-testid={option.dataTestId}
                  >
                    {option.text}
                  </MenuItem>
                )
              )}
          {multiple && (
            <div
              css={{ position: 'sticky', bottom: 0, backgroundColor: 'white' }}
            >
              <Divider />
              <div
                css={{
                  display: 'flex',
                  justifyContent: 'space-between',
                  padding: theme.space.xs,
                }}
              >
                <Button
                  css={{
                    color: theme.color.black,
                  }}
                  onClick={() => {
                    setFieldValue(name, initialValue);
                    if (name === CONCERNS_FIELD_NAME)
                      handleOnItemClick(initialValue);
                  }}
                  disabled={
                    values ? values[name]?.length === 0 && !dirty : false
                  }
                >
                  Clear All
                </Button>
                <Button
                  css={{
                    backgroundColor: theme.color.black,
                    '&:hover': {
                      backgroundColor: theme.color.darkGray,
                    },
                  }}
                  variant="contained"
                  onClick={() => {
                    if (onSelect) onSelect(values[name]);
                    if (onClose) onClose();
                    setIsSelectOpened(false);
                    setSaveHasBeenClicked(true);
                  }}
                >
                  Save
                </Button>
              </div>
            </div>
          )}
          <MenuItem
            css={{
              display: 'none',
            }}
            hidden
            disabled
            key="-1"
          >
            <div>{placeholder}</div>
          </MenuItem>
        </FieldSelect>
      </FieldControl>
      <CustomFieldSelectWithLabelDialog
        open={isDialogOpened}
        onClose={handleOnClose}
        onSelect={(val: any) => {
          if (onSelect) onSelect(val);
          setSelectedValue(val);
          setFieldValue(name, val);
        }}
        multiple={multiple}
        label={label}
        options={options}
        shareLater={shareLater}
        shareLaterText={shareLaterText}
        setShareLaterIsSelected={setShareLaterIsSelected}
        value={selectedValue}
        placeholder={placeholder}
        initialValue={initialValue}
      />
    </div>
  );
};

interface CustomFieldSelectWithLabelDialogProps {
  open: boolean;
  multiple?: boolean;
  label: string;
  options: CustomOptionProps[];
  shareLater?: boolean;
  shareLaterText?: string;

  setShareLaterIsSelected?(selected: boolean): void;

  value: any;

  onClose(): void;

  onSelect?(value: any): void;

  placeholder?: string;
  initialValue: any;
}

const CustomFieldSelectWithLabelDialog = ({
  open,
  multiple,
  label,
  options,
  shareLater,
  shareLaterText = 'I’ll share later',
  setShareLaterIsSelected,
  value,
  onClose,
  onSelect,
  placeholder,
  initialValue,
}: CustomFieldSelectWithLabelDialogProps) => {
  const [isOpen, setIsOpen] = React.useState(open);
  const [selectedValue, setSelectedValue] = React.useState(value);

  React.useEffect(() => {
    setIsOpen(open);
  }, [open]);

  const handleOnClose = () => {
    onClose();
    // set the value back to the initial value if the Delete Cross Icon was clicked
    setSelectedValue(value);
  };

  const handleOnItemClick = (val: any) => {
    if (multiple) {
      if (selectedValue.find((item: any) => item === val)) {
        // if value has already been selected
        const newArray = selectedValue.filter((item: any) => item !== val);
        setSelectedValue(newArray);
      } else setSelectedValue([...selectedValue, val]);
    } else {
      if (onSelect) onSelect(val);
      setSelectedValue(val);
      onClose();
    }
  };

  return (
    <Dialog
      open={isOpen}
      TransitionComponent={SlideTransition}
      keepMounted={false}
      fullWidth={true}
      scroll="body"
      onClose={onClose}
      fullScreen={true}
    >
      <div
        css={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
          fontFamily: theme.fontFamily.postGrotesk,
          padding: theme.space.base,
          borderBottom: `1px solid ${theme.color.border}`,
        }}
      >
        <div css={{ margin: 0, fontSize: theme.fontSize.lg }}>
          {placeholder}
        </div>
        <IconButton
          onClick={handleOnClose}
          css={{
            margin: `-${theme.space.xs}`,
          }}
          size="large"
        >
          <CloseTwoTone
            css={{
              width: 24,
              height: 24,
            }}
          />
          <VisuallyHidden>Close</VisuallyHidden>
        </IconButton>
      </div>
      {shareLater && (
        <>
          <div css={{ marginTop: theme.space.xs }}>
            <ButtonBase
              css={shareLaterStyle}
              onClick={() => {
                if (setShareLaterIsSelected) setShareLaterIsSelected(true);
                if (onSelect) onSelect(initialValue);
                if (onClose) onClose();
                setSelectedValue(initialValue);
              }}
            >
              {shareLaterText}
            </ButtonBase>
          </div>
          <Divider
            css={{
              marginLeft: theme.space.lg,
              marginRight: theme.space.lg,
            }}
          />
          <div css={{ height: theme.space.xs }} />
        </>
      )}
      {multiple
        ? options.map((option: CustomOptionProps) => {
            return (
              <MenuItem
                key={option.key}
                value={option.value}
                css={{
                  padding: `0 ${theme.space.xs} 0 ${theme.space.xs}`,
                  fontFamily: theme.fontFamily.postGrotesk,
                }}
                onClick={() => {
                  if (shareLater)
                    if (setShareLaterIsSelected) setShareLaterIsSelected(false);
                  handleOnItemClick(option.value);
                }}
                selected={
                  selectedValue.find((val: any) => val === option.value)
                    ? true
                    : false
                }
              >
                <ListItemIcon>
                  <Checkbox
                    checked={
                      selectedValue.find((val: any) => val === option.value)
                        ? true
                        : false
                    }
                  />
                </ListItemIcon>
                <ListItemText
                  primaryTypographyProps={{
                    style: {
                      fontFamily: theme.fontFamily.postGrotesk,
                    },
                  }}
                  primary={option.text}
                />
              </MenuItem>
            );
          })
        : options.map((option: CustomOptionProps) => {
            return option.disabled ? (
              <ListSubheader
                disableSticky
                css={{ fontFamily: theme.fontFamily.postGrotesk }}
                key={option.key}
              >
                {option.text}
              </ListSubheader>
            ) : (
              <MenuItem
                key={option.key}
                id={option.key}
                value={option.value}
                onClick={(ev) => {
                  if (shareLater)
                    if (setShareLaterIsSelected) setShareLaterIsSelected(false);
                  handleOnItemClick(option.value);
                }}
                selected={option.value === selectedValue}
              >
                {option.text}
              </MenuItem>
            );
          })}
      {multiple && (
        <div css={{ position: 'sticky', bottom: 0, backgroundColor: 'white' }}>
          <Divider />
          <div
            css={{
              display: 'flex',
              justifyContent: 'space-between',
              padding: theme.space.sm,
            }}
          >
            <Button
              css={{
                color: theme.color.black,
              }}
              onClick={() => setSelectedValue([])}
              disabled={selectedValue.length === 0}
            >
              Clear All
            </Button>
            <Button
              variant="contained"
              onClick={() => {
                if (onSelect) onSelect(selectedValue);
                onClose();
              }}
              css={{ backgroundColor: theme.color.black }}
            >
              Save
            </Button>
          </div>
        </div>
      )}
    </Dialog>
  );
};

export default CustomFieldSelectWithLabel;
