import { sortBy } from 'lodash';

import { SpecialtyRead } from '@headway/api/models/SpecialtyRead';

export type PatientVisibleSpecialtyRead = Omit<
  SpecialtyRead,
  'patientDisplayName'
> & { patientDisplayName: string };

export const selectPatientVisibleSpecialties = (
  specialties: SpecialtyRead[]
): PatientVisibleSpecialtyRead[] => specialties.filter(hasPatientDisplayName);

export const hasPatientDisplayName = (
  specialty: SpecialtyRead
): specialty is PatientVisibleSpecialtyRead => !!specialty.patientDisplayName;

export const sortByPatientDisplayName = (
  specialties: PatientVisibleSpecialtyRead[]
) =>
  sortBy(specialties, (specialty) =>
    specialty.patientDisplayName.toLowerCase()
  );

export const sortByClinicalDisplayName = (specialties: SpecialtyRead[]) =>
  sortBy(specialties, (specialty) =>
    specialty.clinicalDisplayName.toLowerCase()
  );
