import { UnitedStates } from '@headway/api/models/UnitedStates';

export const shouldShowStepDownModal = (
  state: UnitedStates,
  selectedConcerns: string[],
  flagEnabledCalifornia: boolean,
  flagEnabledPennsylvania: boolean,
  isCarrierRequiringAuthorizationSelected: boolean
) => {
  const stateIsCalifornia = state === UnitedStates.CALIFORNIA;
  const stateIsPennsylvania = state === UnitedStates.PENNSYLVANIA;
  const stepDownConcerns = ['anxiety', 'depression', 'stress'];
  // we want to show the modal if the patient chooses no concerns,
  // or if every one of their concerns is anxiety/depression/stress
  // (.every is trivially true when called on an empty array)
  const hasStepDownConcerns = selectedConcerns.every((c: string) => {
    return stepDownConcerns.includes(c);
  });

  return (
    ((flagEnabledCalifornia && stateIsCalifornia) ||
      (flagEnabledPennsylvania && stateIsPennsylvania)) &&
    hasStepDownConcerns &&
    !isCarrierRequiringAuthorizationSelected
  );
};

export const shouldShowStepUpModal = (
  state: UnitedStates,
  selectedConcerns: string[],
  flagEnabledNewYork: boolean
) => {
  const stateIsNewYork = state === UnitedStates.NEW_YORK;
  const stepUpConcerns = ['chronic_conditions', 'diabetes'];
  const hasStepUpConcerns = selectedConcerns.some((c: string) => {
    return stepUpConcerns.includes(c);
  });

  return flagEnabledNewYork && stateIsNewYork && hasStepUpConcerns;
};
