import { css, Theme } from '@emotion/react';
import {
  ArrowForwardTwoTone,
  ChevronLeftOutlined,
  ChevronRightOutlined,
} from '@mui/icons-material';
import { Divider, IconButton, useMediaQuery } from '@mui/material';
import MaterialLink from '@mui/material/Link';
import { AxiosRequestConfig } from 'axios';
import { InferGetServerSidePropsType } from 'next';
import { NextSeo } from 'next-seo';
import { ParsedUrlQuery } from 'querystring';
import React, { useEffect, useState } from 'react';
import { Organization, WithContext } from 'schema-dts';

import { ProviderSearchTopic } from '@headway/api/models/ProviderSearchTopic';
import { ProviderSearchTopicResponse } from '@headway/api/models/ProviderSearchTopicResponse';
import { UserFreezeReason } from '@headway/api/models/UserFreezeReason';
import { ProviderSearchTopicApi } from '@headway/api/resources/ProviderSearchTopicApi';
import { Banner } from '@headway/helix/Banner';
import imgHikingSrc from '@headway/shared/assets/img/illustrations/Hiking.jpg';
import imgTeaSrc from '@headway/shared/assets/img/illustrations/Tea.jpg';
import imgWateringSrc from '@headway/shared/assets/img/illustrations/Watering.jpg';
import { AGORA_SUBDOMAIN_REDIRECTS } from '@headway/shared/FeatureFlags/flagNames';
import { useFlag } from '@headway/shared/FeatureFlags/react';
import { useActiveUserFreezes } from '@headway/shared/hooks/useUserFreezes';
import { trackPageView } from '@headway/shared/utils/analytics';
import { sanitize } from '@headway/shared/utils/htmlSanitize';
import { Carousel } from '@headway/ui/Carousel';
import {
  CopyBody,
  CopyCaption,
  CopyFooter,
  CopyTitle,
  ListItem,
  OrderedList,
  PrimaryBackground,
  Section,
  SectionContainer,
  SectionPair,
} from '@headway/ui/landing';
import { theme } from '@headway/ui/theme';
import { VisuallyHidden } from '@headway/ui/VisuallyHidden';

import { ButtonLink } from '../components/ButtonLink';
import {
  CoordinationOfBenefitsFreezeMessage,
  OtherFreezeMessage,
  OutOfNetworkFreezeMessage,
} from '../components/FreezeMessage';
import GetStartedContainer from '../components/GetStarted/GetStartedContainer';
import { GetStartedButton } from '../components/GetStartedButton';
import { Image } from '../components/Image/Image';
import { PageWrapper } from '../components/Nav/PageWrapper';
import { PayerPartners } from '../components/Payers/PayerPartners';
import { ProviderSearchByName } from '../components/ProviderSearchByName';
import { ProviderSearchTopicCard } from '../components/ProviderSearchTopicCard/ProviderSearchTopicCard';
import { TwitterTestimonialCarousel } from '../components/TwitterTestimonialCarousel';
import { useRouter } from '../hooks/useRouter';
import { IAuthStore, IUiStore, withStores } from '../stores/withStores';
import {
  getAuthCookie,
  ServerSideRequestWithUserMeData,
  ServerSideResponse,
} from '../utils/cookie';
import { getFullURLForPath } from '../utils/request';
import {
  SearchEntry,
  trackProviderSearchStarted,
} from '../utils/searchAnalyticEvents';

const organizationStructuredData: WithContext<Organization> = {
  '@context': 'https://schema.org',
  '@type': 'Organization',
  image: 'https://assets.headway.co/img/headway-logo.png',
  url: 'https://headway.co/',
  logo: 'https://assets.headway.co/img/headway-logo.png',
  name: 'Headway',
  description: `Headway is building a new mental health care system that everyone can access by making it easy for therapists to take insurance and scale their practice. Today, we're the largest mental health provider network in the country, with 34,000 providers across race, gender, ethnicity and specialty working with Headway to run their private practice. Patients can schedule care within 48 hours via one-click booking at headway.co, or can get care through a referral. The company operates in all 50 states and the District of Columbia.`,
  foundingDate: '2018',
  legalName: 'TherapyMatch, Inc',
  sameAs: [
    'linkedin.com/company/find-headway-com/',
    'facebook.com/findheadway/',
    'x.com/try_headway',
    'instagram.com/headway',
    'trustpilot.com/review/headway.co',
  ],
};

type IndexPageProps = {
  AuthStore: IAuthStore;
  UiStore: IUiStore;
  query?: any;
  flags: { [flag: string]: any };
} & InferGetServerSidePropsType<typeof getServerSideProps>;

export const getServerSideProps = async ({
  req,
  res,
  query,
}: {
  req: ServerSideRequestWithUserMeData;
  res: ServerSideResponse;
  query: ParsedUrlQuery;
}) => {
  const cookie = getAuthCookie(req, res);

  const axiosConfig: AxiosRequestConfig = { headers: cookie ? { cookie } : {} };

  const topics = await ProviderSearchTopicApi.getProviderSearchTopics(
    undefined,
    axiosConfig
  );

  return {
    props: {
      query,
      topics,
    },
  };
};

const IndexPageImpl = (props: IndexPageProps) => {
  const [showSearchByName, setShowSearchByName] = useState(false);
  const router = useRouter();

  const agoraSubdomainRedirectEnabled = useFlag(
    AGORA_SUBDOMAIN_REDIRECTS,
    false
  );

  useEffect(() => {
    trackPageView({ name: 'Homepage' });

    const handleRouting = async () => {
      if (props.query && props.query.token) {
        if (router.asPath.includes('/reset-password')) {
          props.AuthStore.resetPasswordToken = props.query.token;
          props.AuthStore.openResetPasswordModal();
        } else if (router.asPath.includes('/invite')) {
          if (!props.AuthStore.user?.isVerified) {
            props.AuthStore.inviteToken = props.query.token;
            props.AuthStore.openSignupModal();
          } else if (
            router.query.referrer &&
            typeof router.query.referrer === 'string' &&
            router.query.referrer.startsWith('/') // Only allow paths to prevent XSS.
          ) {
            await router.push(router.query.referrer);
          }
        }
      }
    };

    handleRouting();
  }, []);

  const isProvider =
    props.AuthStore.user.providerId ||
    !!props.AuthStore.user?.groupPracticeUsers?.length;

  const { data: userFreezeReasons = [] } = useActiveUserFreezes(
    props.AuthStore.user.id
  );
  const hasCobFreeze = userFreezeReasons.includes(
    UserFreezeReason.COB_NEEDS_PAYER_CONFIRMATION
  );
  const hasOonFreeze = userFreezeReasons.includes(UserFreezeReason.OON_PLAN);
  const hasOtherFreeze = userFreezeReasons.includes(UserFreezeReason.OTHER);
  const renderCobFreezeBanner = () => {
    return (
      <Banner variant={'error'}>
        <CoordinationOfBenefitsFreezeMessage />
      </Banner>
    );
  };
  const renderOtherFreezeBanner = () => {
    return (
      <Banner variant={'error'}>
        <OtherFreezeMessage />
      </Banner>
    );
  };
  const renderOonFreezeBanner = () => {
    return (
      <Banner variant={'error'}>
        <OutOfNetworkFreezeMessage />
      </Banner>
    );
  };

  return (
    <React.Fragment>
      <script
        type="application/ld+json"
        dangerouslySetInnerHTML={{
          __html: sanitize(JSON.stringify(organizationStructuredData)),
        }}
      />
      <NextSeo
        title="Find Therapists Covered by Your Insurance"
        titleTemplate="Headway | %s"
        description="Find therapists near you who accept insurance. We'll help you find the right fit—and save you money. Get started today with online or in-person sessions."
        openGraph={{}} // empty object currently needed due to https://github.com/garmeeh/next-seo/issues/544
        canonical={getFullURLForPath(agoraSubdomainRedirectEnabled, '/')}
      />
      <PageWrapper
        ctaLabel={isProvider ? 'Provider Portal' : undefined}
        ctaHref={isProvider ? process.env.NEXT_PUBLIC_SIGMUND_URL : undefined}
        banner={
          hasCobFreeze
            ? renderCobFreezeBanner()
            : hasOonFreeze
            ? renderOonFreezeBanner()
            : hasOtherFreeze
            ? renderOtherFreezeBanner()
            : null
        }
      >
        <SectionContainer>
          <div css={aboveFoldContainerCss}>
            <div css={aboveFoldChildContainerCss}>
              <div css={{ display: 'flex' }}>
                <div css={aboveFoldCopyContainerCss}>
                  <h1 css={aboveFoldTitleCss}>
                    Let us find your mental health provider.
                  </h1>
                  <div css={aboveFoldCopyCss}>
                    Whether you know what you need or aren't sure where to
                    start, we'll help you find the right fit — covered by your
                    insurance.
                  </div>
                </div>
                <Image
                  css={aboveFoldImageCss}
                  src={imgWateringSrc}
                  alt="Person watering plant"
                  sizes="(max-width: 768px) 375px, 850px"
                />
              </div>
              <div css={searchContainerCss}>
                <div css={searchInputCss}>
                  {showSearchByName ? (
                    <div css={searchByNameCss}>
                      <ProviderSearchByName />
                    </div>
                  ) : (
                    <GetStartedContainer />
                  )}
                </div>
                <MaterialLink
                  color={theme.color.textGray}
                  component="button"
                  css={searchByButtonCss}
                  onClick={() => {
                    if (!showSearchByName) {
                      trackProviderSearchStarted({
                        context: {
                          source: SearchEntry.LandingPageSearchName,
                        },
                      });
                    }
                    setShowSearchByName((curr) => !curr);
                  }}
                  variant="body1"
                >
                  {showSearchByName
                    ? 'Or discover new providers'
                    : 'Or look up providers by name'}
                </MaterialLink>
              </div>
            </div>
            <Image
              css={aboveFoldImageSmallScreenCss}
              src={imgWateringSrc}
              alt="Person watering plant"
              sizes="(max-width: 768px) 375px, 850px"
            />
          </div>
        </SectionContainer>

        {props.topics && props.topics.length > 0 && (
          <ProviderSearchTopicCards topics={props.topics} />
        )}

        <SectionContainer>
          <SectionPair reverse={true}>
            <Section>
              <CopyCaption>Use your insurance</CopyCaption>
              <CopyTitle>See how much you save on sessions</CopyTitle>
              <CopyBody>
                Our average client saves 75% on sessions through Headway. We’ll
                work with your insurance so you can focus on your care, without
                worrying about cost.
              </CopyBody>
              <CopyFooter>
                <ButtonLink
                  color="primary"
                  variant="contained"
                  size="large"
                  endIcon={<ArrowForwardTwoTone />}
                  href="/does-my-insurance-cover-therapy"
                  data-testid="doesMyInsuranceCoverTherapyLink"
                >
                  Find your price
                </ButtonLink>
              </CopyFooter>
            </Section>
            <Section>
              <Image
                src={imgTeaSrc}
                alt="Two people drinking tea"
                sizes="(max-width: 768px) 375px, 850px"
              />
            </Section>
          </SectionPair>
        </SectionContainer>

        <SectionContainer>
          <Section centeredBreakpoint={theme.media.smallDown}>
            <CopyCaption>Insurances accepted</CopyCaption>
            <CopyTitle>Use your insurance for mental health care</CopyTitle>
            <CopyBody>
              Most therapists and psychiatrists don't accept insurance. With
              Headway, you can use your Aetna, Anthem Blue Cross and Blue
              Shield, United, Cigna, Oscar, and Oxford insurance plans to pay
              for mental health care with more insurance partners coming soon.
            </CopyBody>
            <PayerPartners />
          </Section>
        </SectionContainer>

        <SectionContainer id="how-it-works">
          <SectionPair reverse={true}>
            <Section>
              <CopyCaption>Getting started</CopyCaption>
              <CopyTitle>How it works</CopyTitle>
              <CopyBody>
                <OrderedList>
                  <ListItem>
                    <p>
                      <strong>Find the right fit</strong>
                    </p>
                    Share your preferences and we’ll filter through thousands of
                    therapists and psychiatrists to find your matches.
                  </ListItem>
                  <ListItem>
                    <p>
                      <strong>Get the in-network price</strong>
                    </p>
                    Add your insurance details so we can estimate your cost.
                  </ListItem>
                  <ListItem>
                    <p>
                      <strong>Book your session</strong>
                    </p>
                    Book right on Headway—we’ll handle everything from there.
                    You’ll only be billed after your session.
                  </ListItem>
                </OrderedList>
              </CopyBody>
              <CopyFooter>
                <GetStartedButton
                  user={props.AuthStore.user}
                  ctaCopy="Find your provider"
                  id="market-how-it-works"
                />
              </CopyFooter>
            </Section>
            <Section>
              <Image
                src={imgHikingSrc}
                alt="Two people hiking."
                sizes="(max-width: 768px) 375px, 850px"
              />
            </Section>
          </SectionPair>
        </SectionContainer>

        <TwitterTestimonialCarousel />

        <SectionContainer>
          <PrimaryBackground>
            <SectionPair>
              <Section>
                <CopyTitle>
                  Are you a mental health care provider or group practice?
                </CopyTitle>
                <CopyBody>
                  Offer affordable, in-network care without the administrative
                  burden. So you can focus on what matters most.
                </CopyBody>
              </Section>
              <Section>
                <div
                  css={{
                    display: 'flex',
                    justifyContent: 'flex-start',
                    marginTop: theme.space.xl,
                    [theme.media.small]: {
                      justifyContent: 'flex-end',
                      marginTop: 0,
                    },
                  }}
                >
                  <ButtonLink
                    color="primary"
                    variant="contained"
                    size="large"
                    endIcon={<ArrowForwardTwoTone />}
                    href="/for-providers"
                    aria-label="Learn more for providers and group practices"
                  >
                    Learn more
                  </ButtonLink>
                </div>
              </Section>
            </SectionPair>
          </PrimaryBackground>
        </SectionContainer>

        <Divider />

        <SectionContainer>
          <Section centered={true}>
            <CopyTitle>Life can be hard.</CopyTitle>
            <CopyBody>
              Finding someone who can make it easier shouldn’t be.
            </CopyBody>
            <CopyFooter>
              <GetStartedButton
                css={{ marginRight: 'auto', marginLeft: 'auto' }}
                user={props.AuthStore.user}
                ctaCopy="Find your provider"
                id="market-footer"
              />
            </CopyFooter>
          </Section>
        </SectionContainer>
      </PageWrapper>
    </React.Fragment>
  );
};

const topicSearchSectionCss = css`
  &#topics-carousel {
    padding: 0 1.5rem 6rem;
  }
  display: grid;
  gap: ${theme.space.xl2} ${theme.space.base};
  grid-template-columns: 100%;
  grid-template-areas:
    'header'
    'cards'
    'cta';

  ${theme.media.small} {
    grid-template-areas:
      'header pagination'
      'cards cards';
    grid-template-columns: 1fr 1fr;
  }
`;

const paginationCss = css`
  grid-area: pagination;
  display: flex;
  gap: ${theme.space.sm};
  align-items: center;
  justify-content: end;

  ${theme.media.smallDown} {
    display: none;
  }
`;

const topicCardsCss = css`
  grid-area: cards;

  .carousel-inner {
    padding-bottom: ${theme.space.lg};
    --shadow-allowance: ${theme.space.lg};

    ${theme.media.smallDown} {
      padding-left: var(--shadow-allowance);
      padding-right: var(--shadow-allowance);
      margin-left: calc(-1 * var(--shadow-allowance));
      margin-right: calc(-1 * var(--shadow-allowance));
      margin-bottom: calc(-1 * var(--shadow-allowance));
    }
  }

  .carousel-item-wrapper {
    ${theme.media.smallDown} {
      min-width: 240px;
    }
  }
`;

const circleIconButtonCss = (muiTheme: Theme) => ({
  '--color': theme.color.black,
  '&[disabled]': {
    '--color': muiTheme.palette.action.disabled,
  },
  border: `1px solid var(--color)`,
  color: 'var(--color)',
});

export interface ProviderSearchTopicCardsProps {
  topics: ProviderSearchTopicResponse[];
}
export const ProviderSearchTopicCards = ({
  topics,
}: ProviderSearchTopicCardsProps) => {
  // TODO(nathanforce):
  // Commented this out in the interest of time. Ideally we hide cards that aren't visible
  //  so that they aren't tabable. This worked when using native el.scrollIntoView(),
  // but that function is not supported in Safari for horizontal (aka 'inline') scrolling.
  //  When I updated to the polyfill it somehow stopped firing the intersection observer.
  // I'll revisit this entire scrolling functionality once we launch.
  // const isAtLeastSmallViewport = useMediaQuery(theme.media.small);
  // React.useEffect(() => {
  //   if (!isAtLeastSmallViewport) {
  //     return;
  //   }

  //   const container = containerRef.current;

  //   if (!container) {
  //     return;
  //   }

  //   const observer = new IntersectionObserver(
  //     (entries) => {
  //       for (const entry of entries) {
  //         if (!entry.isIntersecting) {
  //           // entry.target.style.visibility = 'hidden';
  //         } else {
  //           // entry.target.style.visibility = 'visible';
  //         }
  //       }
  //     },
  //     {
  //       root: container,
  //       rootMargin: '-8px',
  //     }
  //   );

  //   const cards = Array.from(container.children);

  //   for (const card of cards) {
  //     observer.observe(card);
  //   }

  //   return () => {
  //     for (const card of cards) {
  //       observer.unobserve(card);
  //       card.style.removeProperty('visibility');
  //     }
  //   };
  // }, [isAtLeastSmallViewport]);

  const onTopicClick = (topic: ProviderSearchTopic) => {
    trackProviderSearchStarted({
      filters: { topics: [topic] },
      context: { source: SearchEntry.LandingPageTopicCarousel },
    });
  };
  const isAtLeastLargeViewport = useMediaQuery(theme.media.large);
  const isSmallViewport = useMediaQuery(theme.media.smallDown);

  return (
    <SectionContainer id="topics-carousel" css={topicSearchSectionCss}>
      <div>
        <CopyTitle>Find the right person for what you need</CopyTitle>
      </div>

      <Carousel
        renderControls={({ next, prev, hasNext, hasPrev }) => (
          <div css={paginationCss}>
            <ButtonLink variant="outlined" href="/topics" color="black">
              Explore all
            </ButtonLink>
            <IconButton
              disabled={!hasPrev}
              size="small"
              css={circleIconButtonCss}
              onClick={prev}
            >
              <ChevronLeftOutlined
                css={{
                  width: '1.5rem',
                  height: '1.5rem',
                }}
              />
              <VisuallyHidden>Previous page</VisuallyHidden>
            </IconButton>
            <IconButton
              css={circleIconButtonCss}
              size="small"
              disabled={!hasNext}
              onClick={next}
            >
              <ChevronRightOutlined
                css={{
                  width: '1.5rem',
                  height: '1.5rem',
                }}
              />
              <VisuallyHidden>Next page</VisuallyHidden>
            </IconButton>
          </div>
        )}
        items={topics.map((topic) => {
          return (
            <div
              css={{
                padding: isSmallViewport ? 0 : `0 ${theme.space.sm}`,
                height: '100%',
              }}
            >
              <ProviderSearchTopicCard onClick={onTopicClick} topic={topic} />
            </div>
          );
        })}
        itemsPerPage={isAtLeastLargeViewport ? 4 : 3}
        css={topicCardsCss}
        gap={isSmallViewport ? theme.space.base : '0px'}
      />

      <ButtonLink
        css={{
          [theme.media.small]: {
            display: 'none',
            gridArea: 'cta',
          },
        }}
        variant="outlined"
        href="/topics"
        color="black"
      >
        Explore all
      </ButtonLink>
    </SectionContainer>
  );
};

const MEDIA_SMALL_TO_DESKTOP = `@media (min-width: ${theme.breakpoints.small}px) and (max-width: 1024px)`;

const aboveFoldContainerCss = css`
  display: flex;
  margin-bottom: ${theme.space.lg};
  ${theme.media.smallDown} {
    flex-direction: column;
    height: auto;
  }
`;
const aboveFoldChildContainerCss = css`
  display: flex;
  flex-direction: column;
  gap: ${theme.space.xl2};
  height: 100%;
  width: 100%;
`;

const aboveFoldCopyCss = css`
  color: ${theme.color.darkGray};
  font-size: ${theme.fontSize.xl};
  width: 72%;
  ${theme.media.smallDown} {
    font-size: ${theme.fontSize.lg};
    width: 100%;
  }
  ${MEDIA_SMALL_TO_DESKTOP} {
    width: 80%;
  }
`;
const aboveFoldCopyContainerCss = css`
  display: flex;
  flex-direction: column;
  gap: ${theme.space.xl2};
`;
const aboveFoldTitleCss = css`
  color: ${theme.color.black};
  font-size: ${theme.fontSize.xl5};
  margin: 0;
  ${theme.media.smallDown} {
    font-size: ${theme.fontSize.xl4};
    width: 100%;
  }
  ${MEDIA_SMALL_TO_DESKTOP} {
    width: 80%;
  }
  @media (min-width: 769px) and (max-width: 842px) {
    width: 90%;
  }
  @media (max-width: 424px) {
    font-size: ${theme.fontSize.xl3};
  }
  @media (max-width: 359px) {
    font-size: ${theme.fontSize.xl2};
  }
`;

const aboveFoldImageCss = css`
  display: none;
  ${MEDIA_SMALL_TO_DESKTOP} {
    width: 300px;
  }
`;
const aboveFoldImageSmallScreenCss = css`
  ${theme.media.smallDown} {
    margin-top: ${theme.space.xl5};
  }
  ${MEDIA_SMALL_TO_DESKTOP} {
    display: none;
  }
`;

const searchByButtonCss = css`
  letter-spacing: 0;
  margin-top: ${theme.space.base};
  padding-left: ${theme.space.xs3};
`;
const searchByNameCss = css`
  width: 500px;
  ${theme.media.smallDown} {
    width: 100%;
  }
`;
const searchContainerCss = css`
  margin: 0;
  width: fit-content;
  ${theme.media.smallDown} {
    width: 100%;
  }
`;
const searchInputCss = css`
  width: 100%;
`;

export default withStores(IndexPageImpl);
