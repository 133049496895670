import { css } from '@emotion/react';
import { MoreHoriz } from '@mui/icons-material';
import queryString from 'query-string';

import { ProviderSearchTopic } from '@headway/api/models/ProviderSearchTopic';
import { ProviderSearchTopicResponse } from '@headway/api/models/ProviderSearchTopicResponse';
import { filtersToQueryParams } from '@headway/shared/utils/providerSearchFilters';
import { theme } from '@headway/ui/theme';
import { VisuallyHidden } from '@headway/ui/VisuallyHidden';

import { Image, StaticImageData } from '../Image/Image';
import { Link } from '../Link';
import imgCareerAnxietySrc from './assets/career-anxiety.jpg';
import img19611Src from './assets/career-anxiety/19611.jpeg';
import img19616Src from './assets/career-anxiety/19616.jpeg';
import img19617Src from './assets/career-anxiety/19617.jpeg';
import img19638Src from './assets/career-anxiety/19638.jpeg';
import imgChallengesWithFoodSrc from './assets/challenges-with-food.jpg';
import img9272Src from './assets/challenges-with-food/9272.jpeg';
import img10396Src from './assets/challenges-with-food/10396.jpeg';
import img12039Src from './assets/challenges-with-food/12039.jpeg';
import img22814Src from './assets/challenges-with-food/22814.jpeg';
import imgDivorceSeparationSrc from './assets/divorce-separation.jpg';
import img17778Src from './assets/divorce-separation/17778.jpeg';
import img17788Src from './assets/divorce-separation/17788.jpeg';
import img17822Src from './assets/divorce-separation/17822.jpeg';
import img17832Src from './assets/divorce-separation/17832.jpeg';
import imgFamilyStrugglesSrc from './assets/family-struggles.jpg';
import img3293Src from './assets/family-struggles/3293.jpeg';
import img9746Src from './assets/family-struggles/9746.jpeg';
import img16957Src from './assets/family-struggles/16957.jpeg';
import img25516Src from './assets/family-struggles/25516.jpeg';
import imgFeelingDownSrc from './assets/feeling-down.jpg';
import img20772Src from './assets/feeling-down/20772.jpeg';
import img21965Src from './assets/feeling-down/21965.jpeg';
import img25591Src from './assets/feeling-down/25591.jpeg';
import img26793Src from './assets/feeling-down/26793.jpeg';
import imgGenderSexualIdentitySrc from './assets/gender-sexual-identity.jpg';
import img1902Src from './assets/gender-sexual-identity/1902.jpeg';
import img3050Src from './assets/gender-sexual-identity/3050.jpeg';
import img3060Src from './assets/gender-sexual-identity/3060.jpeg';
import img3064Src from './assets/gender-sexual-identity/3064.jpeg';
import imgRecentChildSrc from './assets/recent-child.jpg';
import img16755Src from './assets/recent-child/16755.jpeg';
import img16759Src from './assets/recent-child/16759.jpeg';
import img16763Src from './assets/recent-child/16763.jpeg';
import img16816Src from './assets/recent-child/16816.jpeg';
import imgRecentLossSrc from './assets/recent-loss.jpg';
import img20872Src from './assets/recent-loss/20872.jpeg';
import img20882Src from './assets/recent-loss/20882.jpeg';
import img20892Src from './assets/recent-loss/20892.jpeg';
import img20924Src from './assets/recent-loss/20924.jpeg';
import imgRelationshipsSrc from './assets/relationships.jpg';
import img19510Src from './assets/relationships/19510.jpeg';
import img19519Src from './assets/relationships/19519.jpeg';
import img19521Src from './assets/relationships/19521.jpeg';
import img19570Src from './assets/relationships/19570.jpeg';
import imgStressSrc from './assets/stress.jpg';
import img18322Src from './assets/stress/18322.jpeg';
import img21450Src from './assets/stress/21450.jpeg';
import img24007Src from './assets/stress/24007.jpeg';
import img27226Src from './assets/stress/27226.jpeg';
import imgStruggleWithFocusSrc from './assets/struggle-with-focus.jpg';
import img17531Src from './assets/struggle-with-focus/17531.jpeg';
import img17548Src from './assets/struggle-with-focus/17548.jpeg';
import img17554Src from './assets/struggle-with-focus/17554.jpeg';
import img17571Src from './assets/struggle-with-focus/17571.jpeg';

export const topicInfo: Record<
  ProviderSearchTopic,
  {
    title: string;
    description: string;
    illustration: StaticImageData;
    providers: {
      id: number;
      name: string;
      image: StaticImageData;
    }[];
  }
> = {
  [ProviderSearchTopic.FAMILY_STRUGGLES]: {
    title: 'Challenges with family',
    description: 'Difficulties in my family relationships',
    illustration: imgFamilyStrugglesSrc,
    providers: [
      {
        id: 3293,
        name: 'Sharon Rothman',
        image: img3293Src,
      },
      {
        id: 9746,
        name: 'Bonnie Perkins',
        image: img9746Src,
      },
      {
        id: 16957,
        name: 'KC Georgulas',
        image: img16957Src,
      },
      {
        id: 25516,
        name: 'Jocelyn Stott',
        image: img25516Src,
      },
    ],
  },
  [ProviderSearchTopic.CAREER_ANXIETY]: {
    title: 'Career-related anxiety',
    description: 'Uncertainty and stress caused by my career',
    illustration: imgCareerAnxietySrc,
    providers: [
      {
        id: 19611,
        name: 'Andrea Grunblatt',
        image: img19611Src,
      },
      {
        id: 19616,
        name: 'Deborah Rutt',
        image: img19616Src,
      },
      {
        id: 19617,
        name: 'JaNeen Molborn',
        image: img19617Src,
      },
      {
        id: 19638,
        name: 'Amanda Abady',
        image: img19638Src,
      },
    ],
  },
  [ProviderSearchTopic.FEELING_DOWN_NO_MOTIVATION]: {
    title: 'Feeling down or lacking motivation',
    description: 'Feelings of sadness, indifference, or apathy',
    illustration: imgFeelingDownSrc,
    providers: [
      {
        id: 25591,
        image: img25591Src,
        name: 'Antoinette King',
      },
      {
        id: 26793,
        image: img26793Src,
        name: 'Taneesha Torres-McDade',
      },
      {
        id: 21965,
        image: img21965Src,
        name: 'Michael Goulding',
      },
      {
        id: 20772,
        image: img20772Src,
        name: 'Ciara Warren',
      },
    ],
  },
  [ProviderSearchTopic.STRUGGLE_WITH_FOCUS]: {
    title: 'Struggling to focus',
    description: 'Concentration or commitment challenges in my day-to-day life',
    illustration: imgStruggleWithFocusSrc,
    providers: [
      {
        id: 17531,
        image: img17531Src,
        name: 'Joann Paoletti',
      },
      {
        id: 17548,
        image: img17548Src,
        name: 'Hazel Hooven',
      },
      {
        id: 17554,
        image: img17554Src,
        name: 'Jeanne Assinthe',
      },
      {
        id: 17571,
        image: img17571Src,
        name: 'Andrea Mosley',
      },
    ],
  },
  [ProviderSearchTopic.RECENT_LOSS]: {
    title: 'Loss of someone I care for',
    description: 'Coping with grief and loss',
    illustration: imgRecentLossSrc,
    providers: [
      {
        id: 20872,
        image: img20872Src,
        name: 'Stephanie Fischer',
      },
      {
        id: 20882,
        image: img20882Src,
        name: 'Catherine McKendrick',
      },
      {
        id: 20924,
        image: img20924Src,
        name: 'Tiffany Williams',
      },
      {
        id: 20892,
        image: img20892Src,
        name: 'Susan Gilbreath',
      },
    ],
  },
  [ProviderSearchTopic.DIVORCE_SEPARATION]: {
    title: 'Divorce or separation in my life',
    description: 'Emotions and stress in relationship changes',
    illustration: imgDivorceSeparationSrc,
    providers: [
      {
        id: 17778,
        image: img17778Src,
        name: 'Takeesha Hawkins',
      },
      {
        id: 17788,
        image: img17788Src,
        name: 'Deidre Lupia',
      },
      {
        id: 17822,
        image: img17822Src,
        name: 'Sheri Beckford',
      },
      {
        id: 17832,
        image: img17832Src,
        name: 'Richard Moses',
      },
    ],
  },
  [ProviderSearchTopic.GENDER_SEXUAL_IDENTITY]: {
    title: 'Gender and/or sexual identity',
    description: 'Challenges related to my gender and/or sexual identity',
    illustration: imgGenderSexualIdentitySrc,
    providers: [
      {
        id: 1902,
        image: img1902Src,
        name: 'Camilla Mager',
      },
      {
        id: 3050,
        image: img3050Src,
        name: 'Cara DiTaranto',
      },
      {
        id: 3060,
        image: img3060Src,
        name: 'Neena Ajwani',
      },
      {
        id: 3064,
        image: img3064Src,
        name: 'Ania Baumritter',
      },
    ],
  },
  [ProviderSearchTopic.STRESS]: {
    title: 'Managing stress',
    description: 'Identifying and handling stressors within my life',
    illustration: imgStressSrc,
    providers: [
      {
        id: 21450,
        image: img21450Src,
        name: 'Allison Milana-Trinka',
      },
      {
        id: 27226,
        image: img27226Src,
        name: 'Aditya Vora',
      },
      {
        id: 18322,
        image: img18322Src,
        name: 'DeVonne Allen',
      },
      {
        id: 24007,
        image: img24007Src,
        name: 'Yaneisy Guerra',
      },
    ],
  },
  [ProviderSearchTopic.RELATIONSHIPS]: {
    title: 'Challenges with my personal relationships',
    description: 'Difficulties in friendships and romantic relationships',
    illustration: imgRelationshipsSrc,
    providers: [
      {
        id: 19510,
        image: img19510Src,
        name: 'Machanta Newson',
      },
      {
        id: 19519,
        image: img19519Src,
        name: 'Nicole Bailey',
      },
      {
        id: 19521,
        image: img19521Src,
        name: 'Joseph Lee',
      },
      {
        id: 19570,
        image: img19570Src,
        name: 'Charity Knox',
      },
    ],
  },
  [ProviderSearchTopic.RECENT_CHILD]: {
    title: 'Being a new parent',
    description: 'Navigating the emotions and responsibility of parenthood',
    illustration: imgRecentChildSrc,
    providers: [
      {
        id: 16755,
        image: img16755Src,
        name: 'Lauren Black',
      },
      {
        id: 16759,
        image: img16759Src,
        name: 'Yasmin Somnath',
      },
      {
        id: 16816,
        image: img16816Src,
        name: 'Mark Gough',
      },
      {
        id: 16763,
        image: img16763Src,
        name: 'Dori Scallet',
      },
    ],
  },
  [ProviderSearchTopic.CHALLENGES_WITH_FOOD]: {
    title: 'Challenges with food and nourishment',
    description: 'Support with disordered eating',
    illustration: imgChallengesWithFoodSrc,
    providers: [
      {
        id: 22814,
        image: img22814Src,
        name: 'Michelle Byrd',
      },
      {
        id: 9272,
        image: img9272Src,
        name: 'Joanne Ruckel',
      },
      {
        id: 10396,
        image: img10396Src,
        name: 'Sydney James',
      },
      {
        id: 12039,
        image: img12039Src,
        name: 'Alicia Blankenship',
      },
    ],
  },
};

interface ProviderSearchTopicCardProps {
  topic: ProviderSearchTopicResponse;
  onClick?: (topic: ProviderSearchTopic) => void;
  className?: string;
}

export const ProviderSearchTopicCard = ({
  topic,
  className = '',
  onClick = () => {},
}: ProviderSearchTopicCardProps) => {
  const topicData = topicInfo[topic.topic];
  if (!topicData) return null;
  const { title, description, illustration, providers } = topicData;
  const [params] = filtersToQueryParams({ topics: [topic.topic] });
  const searchUrl = `/search?${queryString.stringify(params)}`;

  return (
    <div
      css={topicCardCss}
      className={[className, 'provider-search-topic-card'].join(' ')}
    >
      <Image
        alt=""
        src={illustration}
        css={{
          aspectRatio: '280 / 157',
          backgroundColor: theme.color.backgroundGray,
        }}
        sizes="33vw"
      />
      <div
        css={{
          padding: theme.space.base,
          flex: 1,
          display: 'flex',
          flexDirection: 'column',
        }}
      >
        <h3
          css={{
            fontSize: theme.fontSize.xl,
            color: theme.color.black,
            fontFamily: theme.fontFamily.postGrotesk,
          }}
        >
          <Link
            to={searchUrl}
            onClick={() => onClick(topic.topic)}
            css={{
              fontWeight: theme.fontWeight.bold,
            }}
            data-ld-metric="topic-based-search-card"
          >
            {title}
          </Link>
        </h3>
        <p
          css={{
            marginBottom: theme.space.sm,
            fontSize: theme.fontSize.sm,
            color: theme.color.black,
          }}
        >
          {description}
        </p>
        <div
          css={{
            display: 'flex',
            alignItems: 'center',
            marginTop: 'auto',
          }}
        >
          {providers.map((provider) => {
            return (
              <div
                key={provider.id}
                css={{
                  position: 'relative',
                  '&:not(:first-child)': {
                    marginLeft: -10,
                  },
                }}
              >
                <Image
                  alt="provider-headshot"
                  css={{
                    width: 26,
                    aspectRatio: '1 / 1',
                    borderRadius: '50%',
                    border: '2px solid white',
                    img: {
                      height: '100%',
                      objectFit: 'cover',
                    },
                  }}
                  sizes="48px"
                  src={provider.image}
                />
                <VisuallyHidden>{provider.name}</VisuallyHidden>
              </div>
            );
          })}
          <div
            css={{
              width: 26,
              height: 26,
              borderRadius: '50%',
              backgroundColor: '#F2FDF9',
              border: '2px solid white',
              marginLeft: -10,
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              zIndex: 1, // go over position-absoluted images
            }}
          >
            <MoreHoriz css={{ width: 16, color: theme.color.primary }} />
          </div>
        </div>
      </div>
    </div>
  );
};

const topicCardCss = css`
  box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.07);
  border-radius: 8px;
  overflow: hidden;
  position: relative;
  display: flex;
  flex-direction: column;
  height: 100%;
  & h3 {
    margin-top: 0;
    font-family: ${theme.fontFamily.postGrotesk};
    font-weight: ${theme.fontWeight.bold};
  }

  & a {
    color: ${theme.color.black};
  }

  & a::after {
    position: absolute;
    content: '';
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
  }
`;
