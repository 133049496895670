import { css } from '@emotion/react';
import CloseIcon from '@mui/icons-material/Close';
import {
  Dialog,
  Divider,
  InputLabelProps,
  ListSubheader,
  Popper,
  PopperProps,
} from '@mui/material';
import Autocomplete, { createFilterOptions } from '@mui/material/Autocomplete';
import IconButton from '@mui/material/IconButton';
import TextField from '@mui/material/TextField';
import useMediaQuery from '@mui/material/useMediaQuery';
import React, { useState } from 'react';

import { theme } from '@headway/ui/theme';

export const NO_OPTIONS_FOUND_VALUE = 'None';

type MobileWrapperProps = {
  children: React.ReactElement | React.ReactElement[];
};

const MobileWrapper = (props: MobileWrapperProps) => {
  return (
    <Dialog
      open={true}
      fullScreen={true}
      keepMounted={false}
      fullWidth={true}
      scroll="body"
    >
      <div css={dialogContainerCss}>
        <div css={{ margin: 0, fontSize: theme.fontSize.lg, width: '100%' }}>
          {props.children}
        </div>
      </div>
    </Dialog>
  );
};

type OptionProps = {
  key: React.Key | undefined;
  disabled?: boolean;
  value: string;
  text: string;
  label: string;
};

type DropdownAutocompleteProps = {
  label: string;
  placeholder: string;
  open: boolean;
  disabled: boolean;
  testId: string;
  options: OptionProps[];
  key: string;
  isFormFocused: boolean;
  onOpen: () => void;
  onClick: () => void;
  onClose: () => void;
  onSelect: (id: number | undefined) => void;
};

export const DropdownAutocomplete = ({
  testId,
  label,
  options,
  placeholder,
  disabled,
  key,
  isFormFocused,
  onClick,
  onOpen,
  onClose,
  onSelect,
}: DropdownAutocompleteProps) => {
  const [isOpen, setIsOpen] = useState(false);
  const isMobile = useMediaQuery(theme.media.smallDown);
  const [selectedOption, setSelectedOption] = useState<OptionProps | undefined>(
    undefined
  );
  const isMobileDropdownOpen = isOpen && isMobile;

  const handleOnOpen = () => {
    if (!isMobileDropdownOpen) {
      if (disabled) {
        onClick();
        setIsOpen(false);
      } else {
        onOpen();
        setIsOpen(true);
      }
    }
  };

  const handleOnClose = () => {
    if (isOpen && !isMobileDropdownOpen) {
      setIsOpen(false);
    }
    onClose();
  };

  const handleDialogOnClose = () => {
    onClose();
    setIsOpen(false);
  };

  const handleOnChange = (option: any) => {
    onSelect(option.key);
    setSelectedOption(option);
    setIsOpen(false);
  };

  const Wrapper = isMobileDropdownOpen ? MobileWrapper : React.Fragment;
  return (
    <Wrapper>
      <TypeSearchInputField
        key={key}
        testId={testId}
        label={label}
        options={options}
        isOpen={isOpen}
        placeholder={placeholder}
        value={selectedOption}
        isMobileDropdownOpen={isMobileDropdownOpen}
        isFormFocused={isFormFocused}
        handleOnOpen={handleOnOpen}
        handleOnChange={handleOnChange}
        handleOnClose={handleOnClose}
        handleDialogOnClose={handleDialogOnClose}
      />
    </Wrapper>
  );
};

const CustomPopper = (props: PopperProps) => {
  return (
    <Popper
      {...props}
      style={{
        maxWidth: 'fit-content',
      }}
      placement="bottom"
      sx={{
        '& .MuiAutocomplete-listbox': {
          minWidth: '500px',
          '& .MuiAutocomplete-option': {
            paddingLeft: '0px',
          },
          '& .MuiAutocomplete-option[aria-disabled="true"]': {
            opacity: 1,
            padding: `${theme.space.xs} 0px 0px 0px`,
            '& li ': {
              color: theme.color.black,
              paddingLeft: theme.space.xl4,
            },
            '& li.MuiListSubheader-sticky': {
              lineHeight: theme.fontSize.base,
              paddingTop: theme.space.base,
              paddingBottom: theme.space.xs,
              fontSize: theme.fontSize.sm,
              fontWeight: 600,
            },
          },
        },
        '& .MuiAutocomplete-noOptions': {
          minWidth: '500px',
        },
      }}
      modifiers={[
        {
          name: 'flip',
          options: {
            fallbackPlacements: [],
          },
        },
      ]}
    />
  );
};

const MobileCustomPopper = (props: PopperProps) => {
  return (
    <Popper
      {...props}
      style={{
        width: '100%',
        height: '100%',
      }}
      placement="bottom"
      sx={{
        '& .MuiPaper-root.MuiAutocomplete-paper': {
          height: '100%',
        },
        '& .MuiAutocomplete-listbox': {
          minHeight: '100%',
          paddingTop: '0px',
          marginBottom: '3.5rem',
          '& .MuiAutocomplete-option': {
            paddingLeft: theme.space.base,
            paddingBottom: theme.space.xs2,
            minHeight: theme.space.xl3,
          },
          '& .MuiAutocomplete-option[aria-disabled="true"]': {
            opacity: 1,
            padding: `0px 0px`,
            '& .optionTextCss': {
              paddingLeft: theme.space.xl2,
            },
            '& li ': {
              color: theme.color.black,
              paddingLeft: theme.space.xl4,
            },
            '& li.MuiListSubheader-sticky': {
              lineHeight: theme.fontSize.base,
              paddingTop: theme.space.base,
              paddingLeft: theme.space.xl2,
              paddingBottom: theme.space.xs,
              fontSize: theme.fontSize.sm,
              fontWeight: 600,
            },
          },
        },
        '& .MuiAutocomplete-noOptions': {
          minWidth: '500px',
        },
        '.MuiAutocomplete-option[aria-selected="true"].Mui-focused': {
          backgroundColor: 'rgba(19, 170, 101, 0.12) !important',
        },
      }}
      modifiers={[
        {
          name: 'offset',
          options: {
            offset: [0, 8],
          },
        },
      ]}
    />
  );
};

type TypeSearchInputFieldProps = {
  key: string;
  testId: string;
  label: string;
  options: OptionProps[];
  isOpen: boolean;
  placeholder: string;
  value: OptionProps | undefined;
  isMobileDropdownOpen: boolean;
  isFormFocused: boolean;
  handleOnOpen: () => void;
  handleOnClose: () => void;
  handleOnChange: (option: OptionProps | string) => void;
  handleDialogOnClose: () => void;
};

interface DataTestIdProps extends React.HTMLAttributes<HTMLDivElement> {
  'data-testid': string;
}

const TypeSearchInputField = ({
  options,
  placeholder,
  label,
  testId,
  isOpen,
  value,
  key,
  isMobileDropdownOpen,
  isFormFocused,
  handleOnOpen,
  handleOnClose,
  handleOnChange,
  handleDialogOnClose,
  ...props
}: TypeSearchInputFieldProps) => {
  return (
    <Autocomplete
      key={key}
      css={autoCompleteContainer(isMobileDropdownOpen)}
      id="autocomplete"
      options={options}
      getOptionLabel={(option) => option.text}
      PopperComponent={isMobileDropdownOpen ? MobileCustomPopper : CustomPopper}
      open={isOpen}
      value={value}
      freeSolo={isMobileDropdownOpen}
      onOpen={() => {
        handleOnOpen();
      }}
      filterOptions={createFilterOptions({
        ignoreCase: true,
        stringify: (option) => option.label,
      })}
      onClose={handleOnClose}
      disableClearable
      blurOnSelect
      isOptionEqualToValue={(option, selectedOption) =>
        option.value === selectedOption.value
      }
      getOptionDisabled={(option) => option.disabled || false}
      //freeSolo prop makes onChange value type become T | string and it can't be anything else.
      onChange={(_ev, selectedOption: OptionProps | string) => {
        handleOnChange(selectedOption);
      }}
      renderInput={(params) => {
        return (
          <div css={textFieldCss}>
            <TextField
              {...params}
              inputRef={(input: HTMLInputElement) => {
                if (!isMobileDropdownOpen && !isFormFocused && !!input) {
                  input.blur();
                }
              }}
              label={isMobileDropdownOpen ? '' : label}
              placeholder={placeholder}
              InputLabelProps={
                {
                  shrink: true,
                  onClick: () => {
                    isOpen ? handleOnClose() : handleOnOpen();
                  },
                  'data-testid': 'insurance-carrier-autocomplete',
                } as DataTestIdProps & InputLabelProps
              }
              data-testid={testId}
              fullWidth
            />
            {isMobileDropdownOpen && (
              <IconButton
                aria-label="close"
                onClick={handleDialogOnClose}
                css={iconButtonCss}
              >
                <CloseIcon />
              </IconButton>
            )}
          </div>
        );
      }}
      renderOption={(props, option) => {
        return (
          <li className="listoption" {...props}>
            {option.disabled && option.value !== NO_OPTIONS_FOUND_VALUE ? (
              <div style={{ width: '100%' }}>
                <Divider component="li" variant="fullWidth" />
                <ListSubheader>{option.text}</ListSubheader>
              </div>
            ) : (
              <div
                className="optionTextCss"
                css={{
                  paddingLeft: isMobileDropdownOpen
                    ? theme.space.base
                    : theme.space.xl4,
                }}
              >
                {option.text}
              </div>
            )}
          </li>
        );
      }}
      {...props}
    />
  );
};

const autoCompleteContainer = (isMobileDropdownOpen: boolean = false) => css`
  align-items: center;
  display: flex;
  justify-content: flex-start;
  width: 210px !important;
  ${theme.media.smallDown} {
    width: 100% !important;
    padding-right: 0px;
    padding-top: ${theme.space.sm};
    padding-bottom: ${theme.space.sm};
  }

  #autocomplete::-webkit-input-placeholder {
    opacity: 1;
  }

  #autocomplete-label {
    font-size: 1.3rem;
    font-family: ${theme.fontFamily.postGrotesk};
    color: ${theme.color.black};
    cursor: pointer;
    margin-top: ${theme.space.base};
    left: ${theme.space.sm};
    width: 100vh;
    ${theme.media.smallDown} {
      left: 0px;
    }
  }

  input {
    padding: 0px 0px;
    font-size: ${isMobileDropdownOpen
      ? theme.fontSize.base
      : theme.fontSize.xs};
  }

  .MuiAutocomplete-popupIndicator:active {
    background-color: transparent;
  }

  .MuiAutocomplete-hasPopupIcon {
    display: flex;
    justify-content: center;
    :active {
      background-color: transparent;
    }
  }

  .MuiAutocomplete-inputRoot.MuiOutlinedInput-root {
    cursor: pointer;
    padding-top: ${isMobileDropdownOpen ? theme.space.sm : theme.space.xl};
    padding-left: ${isMobileDropdownOpen ? theme.space.sm : theme.space.xl};
    padding-right: ${theme.space.sm};
    ${theme.media.smallDown} {
      padding-left: ${theme.space.sm};
    }
  }

  .MuiOutlinedInput-root .MuiAutocomplete-input {
    color: ${theme.color.textGray};
    cursor: pointer;
    margin-right: ${theme.space.xs};
    padding: ${theme.space.xs2} 0px 0px ${theme.space.xs3};
  }

  .MuiAutocomplete-endAdornment {
    left: calc(100% - 37px);
    top: calc(50% - 15px);
    button:hover {
      background-color: transparent;
    }
    .MuiTouchRipple-root {
      display: none;
    }
  }

  .MuiOutlinedInput-root fieldset {
    border: none;
  }
`;
const textFieldCss = css`
  width: 100%;
  ${theme.media.smallDown} {
    display: flex;
    width: 100%;
    align-items: center;
  }
`;
const iconButtonCss = css`
  padding: 0px
  height: 2rem;
  width: 2rem;
`;
const dialogContainerCss = css`
  align-items: center;
  border-bottom: 1px solid ${theme.color.border};
  display: flex;
  justify-content: space-between;
  font-family: ${theme.fontFamily.postGrotesk};
  padding: ${theme.space.base};
  height: ${theme.space.xl5};
`;
