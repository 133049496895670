import { css } from '@emotion/react';
import useMediaQuery from '@mui/material/useMediaQuery';
import { Formik } from 'formik';

import { Button } from '@headway/helix/Button';
import { Form } from '@headway/helix/Form';
import { FormControl } from '@headway/helix/FormControl';
import { Link } from '@headway/helix/Link';
import { Radio } from '@headway/helix/Radio';
import { RadioGroup } from '@headway/helix/RadioGroup';
import { SubBodyText } from '@headway/helix/SubBodyText';
import { Modal } from '@headway/ui/Modal';
import { theme } from '@headway/ui/theme';

import BlueCardDesktop from '../../assets/svgs/bluecard-desktop.svg';
import BlueCardMobile from '../../assets/svgs/bluecard-mobile.svg';

interface BlueCardConfirmationModalProps {
  isOpen: boolean;
  onClose: () => void;
  onSubmit: (value: BlueCardMembershipOption | undefined) => void;
}

type BlueCardMembershipOption = 'yes' | 'no';

export const BlueCardConfirmationModal: React.FC<
  BlueCardConfirmationModalProps
> = ({ isOpen, onClose, onSubmit }) => {
  const isMobile = useMediaQuery(theme.media.smallDown);

  type BlueCardMembershipValues = {
    membershipOption: BlueCardMembershipOption | undefined;
  };

  return (
    <Modal
      title="Are you a BlueCard member?"
      open={isOpen}
      keepMounted={false}
      onClose={onClose}
    >
      <p>
        BlueCard members can see Blue Cross Blue Shield providers anywhere
        they’re living or traveling.{' '}
        <Link
          href="https://findheadway.zendesk.com/hc/en-us/articles/9503864886932"
          target="_blank"
          rel="noreferrer"
        >
          Learn more
        </Link>
        <div css={{ margin: `${theme.space.base} 0` }}>
          {isMobile ? <BlueCardMobile /> : <BlueCardDesktop />}
        </div>
      </p>

      <Formik
        initialValues={
          {
            membershipOption: undefined,
          } as BlueCardMembershipValues
        }
        onSubmit={(values) => {
          onSubmit(values.membershipOption);
        }}
      >
        {({ isSubmitting, values }) => (
          <Form>
            <FormControl name="membershipOption" component={RadioGroup}>
              <Radio value="yes">Yes, I’m a BlueCard member</Radio>
              <Radio value="no">No, I’m not a BlueCard member</Radio>
            </FormControl>
            <SubBodyText color="gray">
              Not sure if you’re a BlueCard member? Ask your insurance company
              by calling the phone number on the back of your insurance card.
            </SubBodyText>
            <div css={buttonContainerStyle}>
              <Button
                size="large"
                variant="primary"
                css={buttonStyle}
                disabled={!!!values.membershipOption || isSubmitting}
                type="submit"
              >
                Continue
              </Button>
            </div>
          </Form>
        )}
      </Formik>
    </Modal>
  );
};

const buttonContainerStyle = css`
  display: flex;
  justify-content: flex-end;
  padding-top: ${theme.space.base} !important;
  ${theme.media.smallDown} {
    padding-top: ${theme.space.xl3} !important;
  }
`;
const buttonStyle = css`
  margin-top: ${theme.space.xl2};
  ${theme.media.smallDown} {
    width: 100%;
  }
`;
